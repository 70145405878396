import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import VideoSlider from './VideoSlider';

function Slider() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    setwebsiteDetails({ "slider_details": result.slider_details,"slider_default": result.slider_default,"hotel_menus": result.hotel_menu_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    var iframe
    let hotel_id=websiteDetails && websiteDetails.hotel_menus && websiteDetails.hotel_menus[0].hotel_id

    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();
      
    return (
    <div>
    {(websiteDetails && websiteDetails.slider_default && websiteDetails.slider_default.default_value ==="video")?
        <div className="c-slider">
            {(websiteDetails === undefined) ? '' : <VideoSlider />}
        </div>
        :
        <div id="banner-carousel" className="carousel header-carousel slide">
        <ol className="carousel-indicators">
            {websiteDetails && websiteDetails.slider_details && websiteDetails.slider_details.map(function (item, i) {
                return (
                    <li data-target="#banner-carousel" data-slide-to={i} className={i === 0 ? "active" : ''} key={i}></li>
                )
            })}
        </ol>

        <div className="carousel-inner">

        {websiteDetails && websiteDetails.slider_details && websiteDetails.slider_details.map(function (item, i) {
              let allData = from_date + '|' + to_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + ""   
              let encryptData = btoa(allData);
            //   iframe = encryptData
            return(
            <div className={i===0?"item active":"item"} key={i}>
                <div className="fill" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + item.slider_image + ")" }}></div>
                {/* <div className="header-image">
                    <img src={config.wbImagesEndpoint + '/' + item.slider_image} alt={item.slider_image_alt}/>
                </div> */}
                <div className="carousel-caption">
                    <div className="carousel-wrap">
                        <h1 className="carousel-h1">{item.slider_caption}</h1>
                        <p className="animated text-center fadeInUp">
                                {/* <Link className="btn btn-carousel"
                                    to={{
                                        pathname: config.PATH + 'booknow',
                                        search: '?' + (iframe),
                                    }}
                                >Book Online</Link> */}
                                <a className="btn btn-carousel" href={"https://" + companyDetails.subdomain_name}>Book Online</a>
                            </p>
                    </div>
                </div>
            </div>

        )})}


        </div>

        <a className="left carousel-control" href="#banner-carousel" data-slide="prev">
            <span className="icon-prev"></span>
        </a>
        <a className="right carousel-control" href="#banner-carousel" data-slide="next">
            <span className="icon-next"></span>
        </a>
    </div>
     }
    </div>

    )
}


export default Slider;

